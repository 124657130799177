<template>
    <section class="blockElement bg-white space">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-4">
                    <div class="pt-0 text-center genrateMessage">
                        <v-lazy-image height="100" width="100" class="d-block mx-auto mb-4" src="/assets/images/success14789.png" :alt="$t('pages_success.text5')" />
                        <h3 class="my-3 clash">{{$t('pages_success.text1')}} <span class="greenBox clashmedium">{{$t('pages_success.text2')}}</span></h3>
                        <p class="mb-4 mb-md-5 f-18">{{$t('pages_success.text3')}}</p>
                    </div>
                    <div class="w-100">
                        <router-link class="button fillBtn zulu_btn d-flex px-md-5" :to="{name: 'dashboard_portfolio'}">{{$t('pages_success.text4')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            //const Crypto = require("crypto-js");
            return { store };
            //Crypto
        },
        data() {
            return {};
        },
        mounted() {
            let details = navigator.userAgent;
            let regexp = /android|iphone|kindle|ipad/i;
            if (regexp.test(details)) {
                window.open("zulutrade://payment/success");
            }
        },
        created() {
            this.store.subscriptionTransactionList({},false).then((res)=>{
                if(res){
                    let clear = res.filter((val)=>val.status=='CLEARED')
                    if(clear?.length==1){
                        try {
                            window.gtag('event', 'FTD');
                            window.teads_e.push({
                                conversionType: "Purchase",
                            });
                            window.fbq('trackCustom', 'FTD', {'Event ID' : `${this.store.customerDetail?.id}_FTD` ,'email' : this.store.customerDetail?.email,'value' : '200','country' : this.store.flavordata?.userCountry?.name,'Purchase value' : '200' ,'Currency' : 'USD' },{eventID:  `${this.store.customerDetail?.id}_FTD`});
                        } catch (e) {
                            console.log("cretio ex", e);
                        }
                    }
                }
            })
        },
    };
</script>
